import {createApi} from '@reduxjs/toolkit/query/react'
import {GlobalAppsettings} from "../../common/GlobalSettings";
import {OrganisationId} from "../models/UserProfile";
import { baseQuery } from './BaseQuery';

/**
 * Base api for accessing portal api
 */
export const portalApi = createApi({
    reducerPath: "portalApi",
    baseQuery: baseQuery(GlobalAppsettings.BaseUrl),
    tagTypes: ['user', 'users', 'agreements', 'notifications', 'stock', 'integration', 'store-bindings', 'withdrawals', 'seasons', 'brands'],
    endpoints: _builder => ({})
});

export const publicPortalApi = createApi({
    reducerPath: "publicPortalApi",
    baseQuery: baseQuery(GlobalAppsettings.BaseUrl, false),
    tagTypes: [],
    endpoints: _builder => ({})
});

export type OrganisationRequest<T = {}> = T & { organisationId: OrganisationId }