import { Drawer, Tabs, TabsValue } from "@mantine/core";
import XMark from "@impulso/common/Icons/XMark";
import { useState } from "react";
import { useGetNotificationsQuery } from "../../api/NotificationApi";
import { useOrganisationId } from "../../common/security/UseGlobalSecurity";
import minutesToMilliseconds from "date-fns/minutesToMilliseconds";
import {networkToClient, notificationFilterFunc, NotificationList, TabValue} from "./Notification";
interface HeaderProps {
  onClose: ()=>void;
}

function Header({onClose}: HeaderProps){
return <div className="flex flex-shrink justify-between">
  <span className="font-semibold">
    Notifications
  </span>
  <div onClick={() => onClose()} className="flex cursor-pointer">
    <XMark/>
  </div>
</div>;
}

interface TabItemProps{
  value: TabValue;
  currentTab: TabValue;
  label: string;
}

function TabItem({value, currentTab, label}: TabItemProps) {
  return <Tabs.Tab value={value}>
    <span className={currentTab === value ? "text-brand-600" : "text-gray-400"}>{label}</span>
  </Tabs.Tab>;
}

interface NotificationDrawerProps {
  opened: boolean;
  onClose: ()=>void;
}

export function NotificationDrawer(props: NotificationDrawerProps) {
  const [currentTab, setCurrentTab] = useState("inbox" as TabValue);
  const zIndex = 10001; //intercom is 10000 so we need to be after it
  const organisationId = useOrganisationId();

  const {data: requestNotifications} = useGetNotificationsQuery(
    {organisationId: organisationId!}, {
      skip: !organisationId,
      pollingInterval: minutesToMilliseconds(5)
    });

  function onTabChanged(value: TabsValue) {
    if (value != null) {
      setCurrentTab(value as TabValue);
    }
  }

  const notifications = requestNotifications
    ?.map(networkToClient)
    .filter(notificationFilterFunc(currentTab))
    .sort((n, n2) => n2.created.getTime() - n.created.getTime()) ?? [];


  return <Drawer 
    opened={props.opened} 
    onClose={props.onClose} 
    position="right"
    overlayProps={{opacity: 0}}
    withCloseButton={false}
    styles={{root: {zIndex, position: "relative"}, body: {padding: 0, margin: 0}}}
    size="515px"
    trapFocus = {false}
  >
    <div className="h-screen flex flex-col overflow-y-hidden p-6 pr-9">
      <Header onClose={props.onClose}/>
      <div className="mt-4 flex flex-col overflow-y-hidden">
        <Tabs value={currentTab} onTabChange={onTabChanged} className="flex flex-col overflow-y-hidden">
          <Tabs.List grow>
            <TabItem value="inbox" currentTab={currentTab} label="Inbox"/>
            <TabItem value="complete" currentTab={currentTab} label="Complete"/>
          </Tabs.List>
            <NotificationList notifications={notifications} onClose={props.onClose} />
        </Tabs>
      </div>
    </div>
  </Drawer>;
}