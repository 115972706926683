import {Alert as MantineAlert} from "@mantine/core"
import {ReactNode} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {colors as theme} from "@impulso/common/Theme";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export interface AlertProps {
    title?: string,
    className?: string,
    type: "warning" | "error" | "info" | "success"
    children?: ReactNode
}

function getIcon(type: "warning" | "error" | "info" | "success") {
    switch (type) {
        case "warning":
        case "error":
            return solid("triangle-exclamation");
        case "info":
            return solid("circle-exclamation");
        case "success":
            return solid("circle-check");
    }
}

export default function Alert(props: AlertProps) {
    const icon = getIcon(props.type);
    const iconElement = icon ? <FontAwesomeIcon size="lg" icon={icon} /> : null;

    const {fg, bg} = colors[props.type];
    const styles = {root: {backgroundColor: bg, color: fg, borderRadius: "0"}, icon: {color: fg}};

    return (
        <MantineAlert icon={iconElement} title={props.title} className={props.className} styles={styles}>
            {props.children}
        </MantineAlert>
    )
}

type TypedAlertProps = Omit<AlertProps, 'type'>;

export function ErrorAlert(props: TypedAlertProps) {
    return <Alert type="error" {...props} />
}

export function WarningAlert(props: TypedAlertProps) {
    return <Alert type="warning" {...props} />
}

export function InfoAlert(props: TypedAlertProps) {
    return <Alert type="info" {...props} />
}

const colors = {
    error: {
        fg: theme.error["600"],
        bg: theme.error["100"]
    },
    warning: {
        fg: theme.warning["900"],
        bg: theme.warning["100"]
    },
    info: {
        fg: "#000000",
        bg: theme.gray["100"]
    },
    success: {
        fg: theme.confirmation["600"],
        bg: theme.confirmation["100"]
    }
}
