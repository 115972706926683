import { useState } from "react";
import { TableReport } from "@impulso/common/components/report/TableReport";
import { Page } from "@impulso/common/components/Page";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { NumberInputField } from "@impulso/common/src/components/inputs/inputField";
import { ArticleRetailerBreakdown, SaveInitialStockRequest, SaveInitialStockResponse, useGetStockBalanceByArticleRetailerQuery, useSaveInitialStockDeliveriesMutation } from "../../api/StockApi";
import { useHasModule, useOrganisation } from "../../common/security/UseGlobalSecurity";
import FilterHeader from "../../common/components/filters/FilterHeader";
import { IFilter, generalFilter } from "@impulso/common/filters/generalFilter";
import { Select, TextInput } from "@mantine/core";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { SecondaryButton } from "@impulso/common/components/buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";
import Paths from "../../configuration/Paths";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { SerializedError } from "@reduxjs/toolkit";
import { TableReportColumn } from "@impulso/common/components/report/DataTable";

type InputDeliveries = {[index: string]:number | ""};
type DeliveriesFilterKey = 'retailer' | 'brand' | 'ean';
type DeliveriesFilter = IFilter<DeliveriesFilterKey>;

export default function EditDeliveries() {
  const organisation = useOrganisation();
  const [inputDeliveries, setDeliveries] = useState({} as InputDeliveries);
  const { currentData: data, isFetching: isFetchingStock, error: stockError} = useGetStockBalanceByArticleRetailerQuery({organisationId: organisation?.id!});
  const [ updateInitialDeliveries, {isLoading: savingInitialDeliveries}] = useSaveInitialStockDeliveriesMutation();
  const [requestRows, setRequestRows] = useState([] as (ArticleRetailerBreakdown)[]);
  const [editResult, setEditResult] = useState(undefined as EditResult | undefined);
  const hasAccess = useHasModule("impulso.supplier.stock.update");

  const [filter, setFilter] = useState(generalFilter({
    "retailer": [],
    "ean": [],
    "brand": []
  }));
  const navigate = useNavigate();

  if (editResult)
  {
    return <ResultPage result={editResult} onContinue={()=>setEditResult(undefined)}/>
  }

  if (data?.length !== undefined && data?.length !== requestRows.length) {
    setRequestRows(data);
  }

  const rows = requestRows.map(r => ({...r, delivered: inputDeliveries[toKey(r)] as number ?? r.deliveredAmount}));

  const filteredRows = rows.filter(filterArticles(filter));

  const columns: TableReportColumn<ArticleRetailerBreakdown & {delivered: number}>[] = GetColumns(savingInitialDeliveries, setDeliveries, inputDeliveries);

  async function confirmDelivery() {
    //flatmap = filtermap
    const deliveries = Object.keys(inputDeliveries).flatMap(k => {
      const splitIndex = k.indexOf(":");
      const ean = k.slice(0, splitIndex);
      const retailerOrgId = k.slice(splitIndex + 1);

      if (inputDeliveries[k] === "")
      {
        return [];
      }
      
      return [{
        ean,
        retailerOrgId,
        amountDelivered: inputDeliveries[k] as number
      }];
    });

      let response = await updateInitialDeliveries({organisationId: organisation?.id!, deliveries});
      type ErrorType = {error: FetchBaseQueryError | SerializedError};
      function isError(response2: typeof response): response2 is ErrorType {
        return !!(response2 as ErrorType).error;
      }

      if (isError(response))
      {
        setEditResult({ 
          skipped: [],
          error: (response.error as FetchBaseQueryError).status.toString()
        });
        return;
      }
      const skippedDeliveries = response.data;
      const getOriginalDelivery = (breakdown: ArticleRetailerBreakdown & { delivered: number }) => {
        if (!skippedDeliveries.find(s => s.ean === breakdown.ean && s.retailerOrgId === breakdown.retailerOrgId)) {
          return breakdown.deliveredAmount ?? inputDeliveries[toKey(breakdown)];
        }
        return breakdown.deliveredAmount;
      };

      const editedDeliveries = deliveries.filter(d => !skippedDeliveries.some(s => s.ean === d.ean && s.retailerOrgId === d.retailerOrgId));
      setEditResult({
        skipped: skippedDeliveries,
        edited: editedDeliveries
      });
      setRequestRows(rows.map(r => ({...r, deliveredAmount: getOriginalDelivery(r)})));
      
      for (let delivery of editedDeliveries)
      {
        delete inputDeliveries[toKey(delivery)];
      }
      setDeliveries({...inputDeliveries});
  }

  const isLoading = isFetchingStock || rows.length === 0;

  return (
    <Page hasAccess={hasAccess} responsive titleKey="editDeliveries.title">
      <EditDeliveriesFilter 
        rows={rows}
        filter={filter} 
        setFilter={setFilter}
        disabled={isFetchingStock || savingInitialDeliveries}
      />
      <TableReport 
        isLoading={isLoading} 
        rows={filteredRows} 
        columns={columns} 
        pageSize={25} 
        defaultSort={{accessor: "articleNumber", direction: "desc"}}
        errorMessage={stockError && "Failed to get stocks"}
        idAccessor={(r)=> toKey(r)}
        loadingText="Loading deliveries..."
        rowHeight="h-[48px]"
        noContentMessage="Failed to find stock events."
      />
      {isLoading ? null : 
        <div className="flex gap-4">
          <PrimaryButton disabled={Object.keys(inputDeliveries).length === 0} loading={savingInitialDeliveries} label="Confirm delivery" extraStyle="!w-min" onClick={() => confirmDelivery()} />
          <SecondaryButton loading={savingInitialDeliveries} label="Cancel" onClick={() => navigate(Paths.productTracker.individualStocks)} />
        </div>
      }
    </Page>
  );
}

function GetColumns(savingInitialDeliveries: boolean, setDeliveries: (val: InputDeliveries)=>void, inputDeliveries: InputDeliveries): TableReportColumn<ArticleRetailerBreakdown & { delivered: number; }>[] {
  return [
    {
      accessor: "delivered",
      title: "Delivered",
      sortable: false,
      visibility: "alwaysVisible",
      render: e => <NumberInputField
        disabled={e.deliveredAmount > 0 || savingInitialDeliveries}
        value={e.delivered}
        onChange={event => {
          if (event === "")
          {
            delete inputDeliveries[toKey(e)];
            setDeliveries({ ...inputDeliveries})
          } else {
            setDeliveries({ ...inputDeliveries, [toKey(e)]: event })
          }
        }}
        label=""
        needsPrecision={false} />
    },
    {
      accessor: "articleNumber",
      title: "Article no",
      sortable: true,
      visibility: "visible",
    },
    {
      accessor: "retailerName",
      title: "Retailer",
      sortable: true,
      visibility: "visible",
    },
    {
      accessor: "ean",
      title: "EAN",
      sortable: true,
    },
    {
      accessor: "brand",
      title: "Brand",
      sortable: true,
      visibility: "hidden",
    },
    {
      accessor: "name",
      title: "Name",
      sortable: true,
      visibility: "visible",
    },
    {
      accessor: "stock",
      title: "Stock (pcs)",
      sortable: true,
      visibility: "visible"
    }
  ];
}

function filterArticles(filter: DeliveriesFilter) {
  let brands = filter.getItems("brand", true).map(b => b.value);
  let retailers = filter.getItems("retailer", true).map(b => b.value);
  let ean = filter.getItems("ean", true).map(b => b.value);

  return (item: ArticleRetailerBreakdown) => {
    if (brands.length > 0 && !brands.some(b => b === item.brand))
    {
      return false;
    }

    if (retailers.length > 0 && !retailers.some(b => b === item.retailerOrgId))
    {
      return false;
    }

    if (ean.length > 0 && !ean.some(b => b === item.ean))
    {
      return false;
    }

    return true;
  }
}

function toKey(item: {ean: string, retailerOrgId: string}): string {
  return item.ean + ":" + item.retailerOrgId;
}

interface FilterProps {
  rows: ArticleRetailerBreakdown[];
  filter: DeliveriesFilter;
  setFilter: (filter: DeliveriesFilter)=>void;
  disabled: boolean;
}

function EditDeliveriesFilter({rows, filter, setFilter, disabled}: FilterProps) {
  const brands = [...new Set(rows.map(r => r.brand))].sort();
  const retailers = [...new Set(rows.map(r => r.retailerOrgId))]
    .map(retailerId => ({
      label: rows.find(r => r.retailerOrgId === retailerId)?.retailerName,
      value: retailerId
    }))
    .sort();

  return <FilterHeader filter={filter} updateFilter={setFilter}>
    <Select
      clearable
      searchable
      disabled={disabled}
      styles={dropdownStyling}
      data={retailers}
      label="Retailer"
      value={filter.getItems('retailer', true).map(s => s.value)[0] ?? undefined}
      onChange={(value) => {
        const label = retailers?.find(r => r.value === value)?.label ?? "Unknown";
        setFilter(filter.overwrite('retailer', value ? [{ label, value, active: true }] : []));
      }}
    />
    <Select
      clearable
      searchable
      disabled={disabled}
      styles={dropdownStyling}
      data={brands.map(b => ({ label: b, value: b }))}
      label="Brand"
      value={filter.getItems('brand', true).map(b => b.value)[0] ?? undefined}
      onChange={(value) => {
        setFilter(filter.overwrite('brand', value ? [{ label: value, value, active: true }] : []));
      }}
    />
    <TextInput
        disabled={disabled}
        styles={dropdownStyling}
        label="EAN"
        value={filter.getItems('ean', true).map(e => e.value)[0] ?? ""}
        onChange={(value) => {
          const input = value.currentTarget.value;
          setFilter(filter.overwrite('ean', input ? [{ label: "EAN: " + input, value: input, active: true }] : []));
        }}
    />
  </FilterHeader>;
}

interface EditResult {
  skipped?: SaveInitialStockResponse[];
  edited?: SaveInitialStockRequest[];
  error?: string;
}

interface ResultPageProps {
  result: EditResult;
  onContinue: ()=>void;
}

function ResultPage({result, onContinue}: ResultPageProps) {

  const ShowError = ()=> {
    return <div>
      <h3>Failed to update deliveries</h3>
      Server responded with unknown error : {result.error}
    </div>;
  }

  const ShowSuccess = ()=> {
    return <div>
      <h3>Updated deliveries</h3>
      <p>
        {result.edited?.length} Deliveries updated successfully.
        {(result.skipped?.length ?? 0) > 0 ?
        <div className="flex-col">
          Skipped deliveries: 
          {result.skipped?.map(s => 
            <div className="content-start m-4">
              <div className="a">
                Ean: {s.ean}
              </div>
              <div>
                Retailer: {s.retailerOrgId}
              </div>
              <div>
                Reason: {s.skipReason}
              </div>
            </div>
          )}
        </div>
        : null}
      </p>
    </div>;
  }

  return <Page hasAccess={true} titleKey="editDeliveries.title">
    <div className="text-center mt-20">
      {result.error !== undefined ? ShowError() : ShowSuccess()}
      <PrimaryButton margin="mt-4 mx-auto" padding="p-2 px-8"  onClick={onContinue} label="Upload more" />
    </div>
  </Page>;
}