import Plus from "@impulso/common/Icons/Plus";
import { Loader } from "@mantine/core";
import {OnClickOutside} from "@impulso/common/common/utilities/OnClickoutside";
import { useCallback, useRef, useEffect  } from "react";
import { IFilter } from "@impulso/common/filters/generalFilter";
import React from 'react';

export type SearchResult<T extends string> = {
    [P in T]: SearchResultItem[];
};

export interface SearchResultItem {
    label: string,
    value: string
}

interface SearchBarProps<T extends string> {
    filter: IFilter<T>;
    setFilter: (filter: IFilter<T>) => void;
    searchString: string;
    setSearch: (query: string) => void;
    searchResult: SearchResult<T>;
    loading: boolean;
    minSearchLength: number;
    placeholder?: string;
}

export default function SearchBar<T extends string>(props: SearchBarProps<T>) {
    const searchResult = props.searchResult;
    const activeFilters = props.filter.getAllActiveItems().filter(b =>  b.key !== 'seasons');
    const hasActive = activeFilters.length > 0;
    const searchActive = props.searchString.length > 0;
    const borderCol = searchActive ? "border-black" : "border-gray-300 hover:border-black";
    const fieldStyle = `relative border border-1 ${hasActive ? 'border-brand-600' : borderCol} col-span-7 min-h-[48px] p-2 gap-2 text-sm grow h-tablet:w-full flex items-center box-border`;
    
    const resultKeys = Object.keys(searchResult) as T[];

    const results = resultKeys.map((key) => {
        return { key, filtered: searchResult[key].filter(sr => !activeFilters.find(af => sr.value === af.item.value)) };
    })
    .filter(category => category.filtered.length > 0)
    .map(category => {
        return <FilterCategory key={category.key} title={category.key} results={category.filtered} filter={props.filter} setFilter={props.setFilter} setInput={props.setSearch} />;
    });

    return (
        <div className={fieldStyle}>
            <ArrayDisplay filter={props.filter} setFilter={props.setFilter} />
            <input type="text" value={props.searchString} 
            onChange={(e) => props.setSearch(e.currentTarget.value)} 
            placeholder={hasActive ? "Search..." : props.placeholder != undefined ? props.placeholder : "Find anything by typing e.g. “Brand 1”, “Retailer 2”"} 
            className="grow self-stretch mx-0 text-base !outline-none" />
            {props.loading ?
                <div className="absolute top-[100%] mt-2 p-2 -right-[1px] -left-[1px] z-30 bg-white border border-1 border-black transition-height duration-500 ease-in-out gap-2">
                    <div className="w-full p-2 flex gap-4 items-center"><Loader size="sm"/><p>Searching...</p></div>
                </div> :
                props.searchString.length > 0 && results.length > 0 ? 
                <div className="absolute top-[100%] mt-2 p-2 -right-[1px] -left-[1px] z-30 bg-white border border-1 border-black transition-height duration-500 ease-in-out gap-2">
                    {results}
                </div> :
                props.searchString.length > 0 && props.searchString.length < props.minSearchLength ? 
                <div className="absolute top-[100%] mt-2 p-2 -right-[1px] -left-[1px] z-30 bg-white border border-1 border-black transition-height duration-500 ease-in-out gap-2">
                    <div className="w-full p-2 flex gap-4 items-center"><p>Search is to short.</p></div>
                </div> : <></>
            }
        </div>
    )
}

function ArrayDisplay<T extends string>(props: { filter: IFilter<T>, setFilter: (filter: IFilter<T>) => void }) {
    return (
        <div className="flex gap-2 flex-wrap">
            {props.filter.getAllActiveItems().filter(b => b.key != 'seasons')
            .map(a => 
            <ActiveFilterBox key={a.item.value} 
                title={a.item.label.toUpperCase()} 
                onClick={() => {props.setFilter(props.filter.removeItem(a.key, a.item))}} 
                />)}
        </div>
    )
}

function FilterCategory<T extends string>(props: { title: T, results: SearchResultItem[], filter: IFilter<T>, setFilter: (filter: IFilter<T>) => void, setInput: (input: string) => void })
{
    const wrapperRef = useRef(null);
    OnClickOutside(wrapperRef, useCallback(() => {
        if (props.title === "sizes") props.setInput("");
    }, []));

    const handleKeyDownEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.setInput("");
      }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDownEsc);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDownEsc);
        };
      }, []);

    return (<div className="p-2" ref={wrapperRef} >
            <p className="text-gray-600">{toTitleCase(props.title)}</p>
            <div className="flex gap-2 pt-1 flex-wrap">
            {props.results.map(r => 
            <ActiveFilterBox key={r.value} 
                title={r.label.toUpperCase()} 
                onClick={() => {
                    props.setFilter(props.filter.addItem(props.title, {...r, active: true})); 
                    props.setInput(props.title === "sizes" ? "sizes" : "")}} 
                    hasPlus />)}
        </div>
    </div>);
}

function toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
    );
}

function ActiveFilterBox(props: { title: string, onClick: () => void,  hasPlus?: boolean }) {
    return (
        <div className="text-white bg-black hover:bg-gray-900 hover:cursor-pointer w-fit flex items-center gap-1 p-1 px-2 select-none"
            onClick={()=>props.onClick()} >
            <p>{props.title}</p>
            <div className={`scale-75 ${props.hasPlus ? '' : 'rotate-45'}`}><Plus /></div>
        </div>
    );
}