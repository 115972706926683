import {portalApi} from "./base/PortalApi";
import {OrganisationId} from "./models/UserProfile";

export const stockApi = portalApi.injectEndpoints({
    endpoints: builder => ({
        getStockBalanceByArticleRetailer: builder.query<ArticleRetailerBreakdownResult, StockRequestParams>({
            query: args => `/api/stock/GetSupplierStockBalance?organisationId=${args.organisationId}`,
            providesTags: ['stock']
        }),
        getMissingArticleCount: builder.query<{ count: number }, { organisationId: OrganisationId }>({
            query: args => ({
                url: `/api/stock/GetMissingArticleCount?organisationId=${args.organisationId}`
            })
        }),
        getMissingArticles: builder.query<{ items: MissingArticlesListItem[] }, { organisationId: OrganisationId }>({
            query: args => ({
                url: `/api/stock/GetMissingArticles?organisationId=${args.organisationId}`
            })
        }),
        saveInitialStockDeliveries: builder.mutation<SaveInitialStockResponse[], { organisationId: OrganisationId, deliveries: SaveInitialStockRequest[]}>({
            query: ({deliveries, ...args}) => ({
                url: `/api/stock/SaveInitialStockDeliveries?organisationId=${args.organisationId}`,
                body: deliveries,
                method: "POST"
            })
        }),
    })
});

export const {
    useGetStockBalanceByArticleRetailerQuery,
    useGetMissingArticleCountQuery,
    useGetMissingArticlesQuery,
    useSaveInitialStockDeliveriesMutation,
} = stockApi;

export type SaveInitialStockRequest = {
    retailerOrgId:string, 
    ean: string, 
    amountDelivered: number
};

export type SaveInitialStockResponse = {
    retailerOrgId:string, 
    ean: string,
    skipReason: string,
};

export type StockRequestParams = {
    organisationId: OrganisationId
}

export type MissingArticlesListItem = {
    ean: string, 
    lastReceived: string, 
    retailerName: string,
    retailerId: OrganisationId, 
    inventories: string[]
}

export type ArticleRetailerBreakdown = {
    ean: string,
    retailerOrgId: string,
    retailerName: string,
    articleNumber: string,
    name: string,
    brand: string,
    stock: number,
    deliveredAmount: number
}

export type ArticleRetailerBreakdownResult = ArticleRetailerBreakdown[];
