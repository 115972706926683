import { GetImage } from "src/common/ImageCache";

export function CreateListElement(name: string, value?: string) {
    const item = document.createElement("li");
    item.innerText = `${name}: ${value ?? "Not specified"}`;
    return item;
}

export function CreateHTMLElementImage(url: string, type: string) {
    const image = GetImage(url);
    image.style.marginBottom = "6px";
    image.width = 128;
    const imageElement = document.createElement(type);
    imageElement.appendChild(image);
    return imageElement;
}
