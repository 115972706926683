import {Modal, ScrollArea} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {useGlobalSecurity, useOrganisationId} from "./common/security/UseGlobalSecurity";
import {useDispatch} from "react-redux";
import {AppDispatch} from "./store";
import {Trans} from "react-i18next";
import {useAuth} from "./common/security/UseAuth";
import {initApp} from "./slices/init";
import NavigationSidebar from "./common/components/header/NavigationSidebar";
import Header from "./common/components/header/Header";
import {useGetUserQuery} from "./api/UserApi";
import Paths from "./configuration/Paths";
import {setOrganisation} from "./slices/OrganisationSlice";
import {OrganisationId} from "./api/models/UserProfile";
import {NotificationDrawer} from "./modules/notifications/NotificationDrawer";
import EditDeliveries from "./pages/inventory/EditDeliveries";
import Deliveries from "./pages/inventory/Deliveries";
import IndividualStocks from "./pages/inventory/IndividualStocks";
import useMobileDetect from "@impulso/common/hooks/useMobileDetect";
import MobileNavigationHeader from "./common/components/header/MobileNavigationHeader";
import { FullscreenError, FullscreenLoader } from "@impulso/common/components/Loader";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { TranslatedPageBoundary } from "@impulso/common/components/PageUpdateReload";
import Users from "./pages/preferences/Users";
import {Partners} from "./pages/new/Partners";
import UserPreferences from "./pages/preferences/UserPreferences";
import MissingArticlesPage from "./pages/preferences/MissingArticles";
import OrgOverviewPage from "./pages/preferences/Overview";

// Lazy load big components so that webpack can chunk them based on dependencies.
const Dashboard = React.lazy(() => import ( /* webpackChunkName: 'Dashboard' */ "./pages/new/Dashboard"));
const Payment = React.lazy(() => import (/* webpackChunkName: 'Payment' */ "./pages/new/Payment"));
const ClientFundsAccount = React.lazy(() => import (/* webpackChunkName: 'ClientFundsAccount' */ "./pages/new/ClientFundsAccount"));
const Withdrawals = React.lazy(() => import (/* webpackChunkName: 'Withdrawals' */ "./pages/new/Withdrawals"));
const VatReport = React.lazy(() => import (/* webpackChunkName: 'VatReport' */ "./pages/new/VatReport"));
const Preferences = React.lazy(() => import (/* webpackChunkName: 'Preferences' */ "./pages/preferences/Preferences"));
const StoreBinding = React.lazy(() => import (/* webpackChunkName: 'StoreBinding' */ "./pages/integrations/StoreBinding"));
const InventoryBinding = React.lazy(() => import (/* webpackChunkName: 'InventoryBinding' */ "./pages/integrations/InventoryBinding"));
const Agreements = React.lazy(() => import (/* webpackChunkName: 'Agreements' */ "./pages/new/Agreements"));
const CreateAgreements = React.lazy(() => import (/* webpackChunkName: 'Agreements' */ "./pages/new/CreateAgreement"));
const Upload = React.lazy(() => import (/* webpackChunkName: 'Upload' */ "./pages/inventory/Upload"));
const ArticleDownload = React.lazy(() => import (/* webpackChunkName: 'ArticleDownload' */ "./pages/inventory/ArticleDownload"));
const ArticleEvents = React.lazy(() => import (/* webpackChunkName: 'ArticleEvents' */ "./pages/inventory/ArticleEvents"));
const ArticleSummary = React.lazy(() => import (/* webpackChunkName: 'ArticleSummary' */ "./pages/inventory/ArticleSummary"));
const FortnoxCallback = React.lazy(() => import (/* webpackChunkName: 'FortnoxCallback' */ "./pages/fortnox/Callback"));
const ShopifyCallback = React.lazy(() => import (/* webpackChunkName: 'ShopifyCallback' */ "./pages/shopify/Callback"));
const SitooConnection = React.lazy(() => import (/* webpackChunkName: 'SitooConnection' */ "./pages/sitoo/SitooConnection"));

export const App = () => {
    const {isLoading, isAuthenticated, userError} = useAuth(true);
    const {logout, accessToken} = useGlobalSecurity();
    const {data, isLoading: loadingUser} = useGetUserQuery(undefined, {skip: !accessToken});
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(initApp());
    }, [dispatch])

    const userProfile = data?.user;
    const hasOrganisation = data?.organisations?.[0];

    if (!isLoading && !isAuthenticated) {
        return <FullscreenLoader message="Loading"/>
    } else if (!isLoading && userError) {
        return <FullscreenError message="Failed to load user" logout={() => logout()}/>
    } else if (isLoading || !userProfile || loadingUser) {
        return <FullscreenLoader message="Loading"/>
    } else if (userProfile && !hasOrganisation) {
        return <FullscreenError message="You don't belong to an organisation yet." logout={() => logout()}/>
    }

    return <Routes>
        <Route path={Paths.fortnox.callback} element={<React.Suspense fallback={<FullscreenLoader message="Loading"/>}><FortnoxCallback /></React.Suspense>} />
        <Route path={Paths.shopify.callback} element={<React.Suspense fallback={<FullscreenLoader message="Loading"/>}><ShopifyCallback /></React.Suspense>} />
        <Route path="*" element={<NewApp />} />
    </Routes>;
};

function NewApp() {
    const {logout, accessToken} = useGlobalSecurity();
    const [showNotifications, setShowNotifications] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const {data, isLoading} = useGetUserQuery(undefined, {skip: !accessToken});
    const hasOrgList = data?.organisations && data.organisations.length > 0;
    const organisations = (hasOrgList ? data?.organisations : undefined) ?? [];
    const organisation = useOrganisationId();
    const dispatch = useDispatch();
    const { isMobileSize, initialized } = useMobileDetect();

    const doSetOrganisation = (id: OrganisationId) => {
        if (id !== organisation) {
            dispatch(setOrganisation(id));
        }
    }

    // Used for clearing page error boundary when navigating
    const location = useLocation();
    const locationPath = location.pathname;
    const params = location.search.substring(1).split("&").map(param => {
        var [key, value] = param.split("=");
        return {
            key,
            value
        };
    });
    const invited = params.find(p => p.key === "invited")?.value;
    const invitedOrganisation = params.find(p => p.key === "organisation")?.value;
    const invitedOrganisationName = invitedOrganisation !== undefined ? decodeURIComponent(invitedOrganisation) : "new";

    const modalContent = <AddedUserModal organisationName={invitedOrganisationName}></AddedUserModal>

    function closeModal()
    {
        setShowModal(false);
        window.history.replaceState({}, document.title,"/");
    }

    return (
        <div className={"flex h-screen overflow-y-hidden absolute inset-0" + (isMobileSize ? " flex-col" : '')}>
            { !initialized ? <div><FullscreenLoader message="Loading"/></div> : 
                <>
                    { isMobileSize 
                    ? <MobileNavigationHeader 
                        userName={data?.user?.name ?? ""} 
                        setNotificationOpen={() => setShowNotifications(true)} 
                        currentOrganisationId={organisation!} 
                        setCurrentOrganisationId={doSetOrganisation}
                        organisations={organisations}
                        organisationLoading={isLoading} /> 
                    : <div><NavigationSidebar onLogoutClick={logout}/></div> }
                    <ScrollArea className="overflow-y-auto flex-grow pb-3">
                        <div>
                            <TranslatedPageBoundary key={locationPath}>

                                <React.Suspense fallback={<div><FullscreenLoader message="Loading"/></div>}>
                                    { !isMobileSize && 
                                        <Header
                                            onLogoutClick={logout}
                                            organisations={organisations}
                                            currentOrganisationId={organisation!}
                                            setCurrentOrganisationId={doSetOrganisation}
                                            organisationLoading={isLoading}
                                            showNotifications={()=>setShowNotifications(true)}
                                        />
                                    }
                                    <Routes>
                                        <Route index element={<Dashboard setShowNotification={() => setShowNotifications(true)}/>} />
                                        <Route path={Paths.payment.payoutReport} element={<Payment />} />
                                        <Route path={Paths.payment.clientFundsAccount} element={<ClientFundsAccount />} />
                                        <Route path={Paths.withdrawals.withdrawalReport} element={<Withdrawals />} />
                                        <Route path={Paths.withdrawals.vatReport} element={<VatReport />} />
                                        <Route path={Paths.withdrawals.clientFundsAccount} element={<ClientFundsAccount />} />
                                        <Route path={Paths.preferences.index} element={<Preferences />} />
                                        <Route path={Paths.preferences.userPrefs} element={<UserPreferences/>} />
                                        <Route path={Paths.preferences.stores} element={<StoreBinding /> } />
                                        <Route path={Paths.preferences.inventories} element={<InventoryBinding /> } />
                                        <Route path={Paths.agreements.partners} element={<Partners />} />
                                        <Route path={Paths.agreements.partner} element={<Agreements />} />
                                        <Route path={Paths.agreements.create} element={<CreateAgreements />} />
                                        {/* <Route path="/mock-up-kpi" element={<KPIPage />} /> */}
                                        <Route path={Paths.productTracker.deliveries} element={<Deliveries />} />
                                        <Route path={Paths.productTracker.individualStocks} element = {<IndividualStocks />} />
                                        <Route path={Paths.articles.upload} element={<Upload />} />
                                        <Route path={Paths.articles.download} element={<ArticleDownload />} />
                                        <Route path={Paths.articles.articleEvents} element={<ArticleEvents />} />
                                        <Route path={Paths.articles.article} element={<ArticleSummary />} />
                                        <Route path={Paths.productTracker.editDeliveries} element={<EditDeliveries />} />
                                        <Route path={Paths.preferences.users} element={<Users/>} />
                                        <Route path={Paths.preferences.missingArticles} element={<MissingArticlesPage />} />
                                        <Route path={Paths.preferences.overview} element={<OrgOverviewPage />} />
                                        <Route path={Paths.sitoo.connection} element={<SitooConnection />} />
                                        <Route path="*" element={<NotFoundPage />} />
                                    </Routes>
                                    {invited && <Modal withCloseButton={false} opened={initialized && !isLoading && showModal && modalContent !== undefined} onClose={()=>closeModal()}>{modalContent}</Modal>}
                                </React.Suspense>
                            </TranslatedPageBoundary>
                        </div>
                    </ScrollArea>
                </>
            }
            <NotificationDrawer
                opened={showNotifications} 
                onClose={()=>setShowNotifications(false)}
            />
        </div>
    );
}

const NotFoundPage = () => (
    <div className="h-[calc(100vh-10rem)] flex flex-col justify-around ">
        <div className="flex flex-col content-center items-center">
            <FontAwesomeIcon className="block w-16 h-16 text-brand" icon={solid("triangle-exclamation")} />
            <p className="text-center font-light mt-4"><Trans i18nKey="router.pageNotFound" /></p>
        </div>
    </div>
)

interface AddedUserProps
{
    organisationName: string
}

function AddedUserModal(props: AddedUserProps)
{
    return (<div>
        You have been added to the {props.organisationName} workspace.
    </div>);
}