let imageCache: Map<string, HTMLImageElement> = new Map();

export function GetImage(url: string) {

    if (imageCache.size > 10)
    {
        imageCache.clear();
    }

    let image = imageCache.get(url);

    if (image === undefined) {
        image = new Image();
        image.src = url;
        imageCache.set(url, image);
    }

    return image;
}