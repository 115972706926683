import Plus from "@impulso/common/Icons/Plus";
import { Page } from "@impulso/common/components/Page";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { LoadingOverlay, Menu, Select } from "@mantine/core";
import Alert, { InfoAlert } from "src/common/components/panels/Alert";
import UsersReport from "src/modules/users/UsersReport";
import { useCurrentUser, useHasModule, useOrganisation } from "src/common/security/UseGlobalSecurity";
import { OrganisationInfo, useGetOrganisationTreeQuery, useGetUsersQuery, useInviteUserMutation } from "src/api/UserApi";
import { forwardRef, useEffect, useState } from "react";
import { TextInputField } from "@impulso/common/components/inputs/inputField";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { OrganisationBindingRole, OrganisationId } from "src/api/models/UserProfile";

export default function Users() {
    const organisation = useOrganisation()!;
    const { data: users, isLoading } = useGetUsersQuery({ organisationId: organisation.id }, { skip: !organisation });
    const {user} = useCurrentUser();
    const hasAccess = useHasModule("impulso.users");
    const inviteOrganisations = useGetOrganisationTreeQuery({organisationId: organisation.id});

    const usersWithoutYou = users?.filter(u => u.id !== user?.user.id);

    const isAdmin = organisation.role === "Administrator";

    return <Page responsive grid titleKey="users.title" hasAccess={hasAccess} isMobile={false}
        rightAction = {isAdmin && <InviteForm currentOrganisationId={organisation.id} organisations={inviteOrganisations.data ?? []} userId={user!.user.id}/>}>
        {isLoading ? 
            <LoadingOverlay visible={true}/>
          : (usersWithoutYou !== undefined && usersWithoutYou.length > 0) ? 
            <div className="col-span-12 desktop-plus:col-span-18">
                <UsersReport isAdmin={isAdmin} organisationId={organisation.id} users={usersWithoutYou}/>
            </div> :
            <InfoAlert className="col-start-4 h-tablet:col-start-1 col-span-6 h-tablet:mt-0 mt-[50px]">
                <p>No users are connected to this organisation.</p>
            </InfoAlert>
        }
    </Page>
}

interface InviteFormProps
{
    currentOrganisationId: OrganisationId,
    organisations: OrganisationInfo[],
    userId: string
}

function InviteForm(props: InviteFormProps) {
    const [opened, setOpened] = useState(false);
    const [role, setRole] = useState<OrganisationBindingRole>("Member");
    const [alertMessage, setMessage] = useState("");
    const [alertTypeError, setIsError] = useState(true);
    const [organisationId, setOrganisationId] = useState<OrganisationId>(props.currentOrganisationId);
    const [email, setEmail] = useState("");
    const [InviteUser, request] = useInviteUserMutation();
    useEffect(() => {
        if(request.isError) {
            setMessage("Failed to invite " + email);
            setIsError(true);
        }
    }, [email, request.isError]);
    useEffect(() => {
        if(request.isSuccess) {
            setMessage("Invited " + email);
            setIsError(false);
            setEmail("");
        }
    }, [email, request.isSuccess]);

    const emailRegExp = new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$", "i");
    const organisationMapping = props.organisations.map(org => ({ value: org.id, label: org.name }));

    const ButtonComponent = forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>((props, ref) => (
        <div ref={ref} {...props}>
            <PrimaryButton disabled={opened} label="Invite" rightIcon={<Plus/>} extraStyle="w-full"/>
        </div>));

    function SendInvite() {
        if(!emailRegExp.test(email)) {
            setMessage("Invalid email");
            setIsError(true);
            return;
        }

        InviteUser({organisationId: props.currentOrganisationId, invitedOrganisationId: organisationId, email: email, role: role});
    }

    return (
        <div>
            <Menu position="bottom-end" opened={opened} onChange={setOpened}>
                <Menu.Target>
                    <ButtonComponent/>
                </Menu.Target>

                <Menu.Dropdown className="p-4 text-sm min-w-[450px] max-w-[350px]">
                    <div className="flex flex-col ">
                        <div className={"grid grid-rows-3 "}>
                            <TextInputField label="Email" size='sm' required={true} value={email} onChange={(i) => setEmail(i.target.value) } disabled={request.isLoading}/>
                            <Select 
                                label="Role" 
                                styles={dropdownStyling} 
                                value={role} 
                                onChange={(value) => setRole(value as OrganisationBindingRole) }
                                data={['Member', 'Administrator']} 
                                disabled={request.isLoading}
                            />
                            <Select 
                                label="Organisation" 
                                styles={dropdownStyling} 
                                value={organisationId} 
                                onChange={(value) => setOrganisationId(value as OrganisationId) }
                                data={organisationMapping} 
                                disabled={request.isLoading}
                            />
                            <div className={"mt-4"}>
                                <PrimaryButton label="Invite User" onClick={() => SendInvite() } disabled={request.isLoading}></PrimaryButton>
                            </div>
                        </div>
                    </div>
                    {alertMessage.length !== 0 && <Alert className="mt-4" type={alertTypeError ? "error" : "success"}>
                            {alertMessage}
                        </Alert>}
                </Menu.Dropdown>
            </Menu>
        </div>
    );
}