import React from "react";

interface ContainerProps {
    child: Node;
}

export class HTMLContainer extends React.Component<ContainerProps> {
    render() {
        return <div ref={ref => ref?.appendChild(this.props.child)}></div>;
    }
}
